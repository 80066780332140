<template>
  <!--begin::Dashboard-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("modals.pollster.createPollster") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->

          <!--begin::Card body-->
          <div class="card-body p-9 pt-1 ps-6 pb-1">
            <Form
              id="poll_create_form"
              :validation-schema="form"
              class="form"
              @submit="onSubmit"
            >
              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark required">{{
                  $t("modals.pollster.pollTitle")
                }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  v-model="poll.title"
                  class="form-control form-control-md form-control-solid"
                  type="text"
                  name="title"
                  autocomplete="off"
                  :placeholder="$t('modals.pollster.enterTitle')"
                />
                <!--end::Input-->
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="title" />
                  </div>
                </div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark required">{{
                  $t("modals.pollster.desc")
                }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  v-model="poll.description"
                  class="form-control form-control-md form-control-solid"
                  as="textarea"
                  type="text"
                  name="desc"
                  autocomplete="off"
                  :placeholder="$t('modals.pollster.enterDesc')"
                />
                <!--end::Input-->
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="desc" />
                  </div>
                </div>
              </div>
              <!--end::Input group-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-md-6">
                  <div class="fv-row mb-10">
                    <label class="required fs-6 fw-bold mb-2">{{
                      $t("modals.pollster.startDate")
                    }}</label>

                    <!--begin::Input-->
                    <div class="position-relative align-items-center">
                      <!--begin::Datepicker-->
                      <Field
                        v-model="poll.startDate"
                        type="date"
                        name="startDate"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('modals.pollster.enterStartDate')"
                        :min="new Date().toISOString().split('T')[0]"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="startDate" />
                        </div>
                      </div>
                      <!--end::Datepicker-->
                    </div>
                    <!--end::Input-->
                  </div>
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-md-6 fv-row">
                  <label class="required fs-6 fw-bold mb-2">{{
                    $t("modals.pollster.endDate")
                  }}</label>

                  <!--begin::Input-->
                  <div class="position-relative align-items-center">
                    <!--begin::Datepicker-->
                    <Field
                      v-model="poll.endDate"
                      type="date"
                      name="endDate"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('modals.pollster.enterEndDate')"
                      :min="new Date().toISOString().split('T')[0]"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="endDate" />
                      </div>
                    </div>
                    <!--end::Datepicker-->
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-10">
                <div class="d-flex align-items-center">
  <label class="form-label me-3 mt-4">
    {{ $t("modals.pollster.singlePollType") }}:
  </label>
  <div>
    <div
      class="
        form-check form-check-custom form-check-solid form-switch
        mb-2
        mt-2
      "
    >
      <input
        class="form-check-input"
        type="checkbox"
        v-model="poll.isPrivate"
      />
      <div class="form-text text-muted ms-4">
        {{ $t("modals.pollster.singlePollTypeDesc") }}
      </div>
    </div>
  </div>
</div>

              </div>

              <div class="form-text text-muted my-4 required">
                {{ $t("common.requiredInputs") }}
              </div>

              <div
  class="row"
  v-for="(question, index) in questions"
  :key="index"
>
  <!-- Soru Metni Alanı -->
  <div class="col-md-7 mb-3">
    <div class="fv-row">
      <label class="form-label fs-6 fw-bolder text-dark required">
        {{ $t("modals.pollster.question") }}
      </label>
      <Field
        class="form-control form-control-md form-control-solid"
        v-model="question.text"
        type="text"
        :name="'question' + index"
        autocomplete="off"
        :placeholder="$t('modals.pollster.enterQuestion')"
      />
      <div class="fv-plugins-message-container">
        <ErrorMessage name="question" class="fv-help-block" />
      </div>
    </div>
  </div>

  <!-- Soru Tipi Alanı -->
  <div class="col-md-4 mb-3">
    <div class="fv-row">
      <label class="form-label fs-6 fw-bolder text-dark required">
        {{ $t("modals.pollster.questionType") }}
      </label>
      <select
        class="form-select form-select-md form-select-solid"
        v-model="question.type"
        @change="updateOptions(index)"
      >
        <option :value="QuestionTypes.OPEN_ENDED">
          {{ $t("modals.pollster.openEnded") }}
        </option>
        <option :value="QuestionTypes.SINGLE_CHOICE">
          {{ $t("modals.pollster.singleChoice") }}
        </option>
        <option :value="QuestionTypes.MULTIPLE_CHOICE">
          {{ $t("modals.pollster.multipleChoice") }}
        </option>
      </select>
    </div>
  </div>

  <!-- Sil Butonu -->
  <div class="col-md-1 d-flex align-items-end mb-4">
    <button
      type="button"
      @click="deleteQuestion(index)"
      class="btn btn-danger"
    >
      {{ $t("common.button.delete") }}
    </button>
  </div>

  <!-- Seçenekler Alanı -->
  <div class="col-12 mb-3" v-if="question.type === QuestionTypes.SINGLE_CHOICE || question.type === QuestionTypes.MULTIPLE_CHOICE">
    <div>
      <label class="fs-7 fw-bold mb-1">
        {{ $t("modals.pollster.responseOptions") }}
      </label>
      <ul class="list-unstyled ms-3">
        <li v-for="(option, idx) in question.options" :key="idx">
          - {{ option.optionText }}
        </li>
      </ul>

      <!-- Yeni Seçenek Ekle -->
      <div class="d-flex align-items-center">
        <input
          class="form-control form-control-md form-control-solid me-2"
          type="text"
          v-model="question.option"
          :placeholder="$t('modals.pollster.addOption')"
          style="flex: 1"
        />
        <button
          type="button"
          @click="addOption(index)"
          class="btn btn-primary"
        >
          {{ $t("common.button.add") }}
        </button>
      </div>
    </div>
  </div>
</div>

              <div class="row mb-2">
                <div class="col-md-4 mb-6">
                  <!--begin::Input group-->
                  <button
                    type="button"
                    @click="addQuestion"
                    id="kt_account_profile_details_submit"
                    ref="submitButton"
                    class="btn btn-warning pt-3 pb-3"
                  >
                    <span class="indicator-label">
                      {{ $t("modals.pollster.addQuestion") }}</span
                    >
                    <span class="indicator-progress">
                      {{ $t("common.pleaseWait") }}
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                  <!--end::Input group-->
                </div>
              </div>
              <!-- begin::Actions-->
              <div class="card-footer d-flex justify-content-center py-6 px-9">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label">
                    {{ $t("common.button.create") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
              <!--end::Actions -->
            </Form>
          </div>
          <!--end::Card body-->
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { SurveyQuestionsModel } from "@/domain/pollster/create-poll/model/SurveyQuestionsModel";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<CreatePollModel>({
      surveyQuestions: [],
      isPrivate: false,
    });

    const questions = ref<
      Array<{
        text: string;
        type: string;
        options: { optionText: string }[];
        option: string;
      }>
    >([]);

    //Create form validation object
    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("Title"),
      desc: Yup.string()
        .required(t("validators_error.required"))
        .label("Description"),
      startDate: Yup.string()
        .required(t("validators_error.required"))
        .label("StartDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("EndDate")
        .test("is-greater", t("validators_error.startDateBiggerEndDate"), function (value) {
          const { startDate } = this.parent;
          return startDate
            ? new Date(value as Date) >= new Date(startDate as Date)
            : true;
        }),
    });

    const addQuestion = () => {
      const newQuestion = {
        text: "",
        type:QuestionTypes.OPEN_ENDED,
        options: [],
        option: "",
      };
      questions.value.push(newQuestion);
      poll.value.surveyQuestions.push(
        new SurveyQuestionsModel("", QuestionTypes.OPEN_ENDED, [])
      );
    };

    const updateOptions = (index: number) => {
      questions.value[index].options = [];
      questions.value[index].option = "";
      poll.value.surveyQuestions[index].surveyQuestionOptions = [];
    };

    const deleteQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const addOption = (index: number) => {
      if (questions.value[index].option) {
        questions.value[index].options.push({
          optionText: questions.value[index].option,
        });
        poll.value.surveyQuestions[index].surveyQuestionOptions.push({
          optionText: questions.value[index].option,
        });
        questions.value[index].option = "";
        (
          document.querySelector(
            `input[ref="questionOption"]`
          ) as HTMLInputElement
        )?.focus();
      }
    };

    const onSubmit = () => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true;

      const surveyQuestions: SurveyQuestionsModel[] = questions.value.map(
        (question) => {
          return {
            questionText: question.text,
            questionType: question.type,
            surveyQuestionOptions: question.options,
          };
        }
      );

      poll.value.surveyQuestions = surveyQuestions;

      pollsterController
        .createPoll(poll.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CREATE_POLL),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            }).then(function () {
              router.push({ name: "polls" });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          loading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
    });

    return {
      form,
      onSubmit,
      addQuestion,
      updateOptions,
      deleteQuestion,
      addOption,
      questions,
      poll,
      QuestionTypes
    };
  },
});
</script>
